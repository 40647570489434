import React from 'react'
import Layout from '../components/layout/layout'
import Footer from '../components/layout/footer'
import { graphql } from 'gatsby'
import { Card, Row, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHiking } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faDiscord, faFacebook, faMedium, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import i18next from 'i18next'

export default function FollowUs({data}) {

  // l10n
  let storyPage

  if (i18next.language === 'cs') {
    storyPage = JSON.parse(data.page.edges[0].node.content)
  } else {
    storyPage = JSON.parse(data.page.edges[1].node.content)
  }

  return (
    <Layout>
      <GatsbySeo
        titleTemplate={storyPage.title + ' | %s'}
      />
      <div className="main-container">
        <header className="text-white">
          <div className="d-flex justify-content-center sm-adjust">
            <h1>{storyPage.title}</h1>
          </div>
        </header>
        <Container className="col-sm-10 text-white">
          <Row className="social-cards pt-4 justify-content-center">
            <a className="social-card-link m-2 text-center" href="https://www.instagram.com/pinavomaps/">
              <Card className="social-card">
                <Card.Body>
                  <Card.Title><FontAwesomeIcon icon={faInstagram} size="2x" color="#3771c8" aria-label="Go to Instagram"/></Card.Title>
                  <Card.Subtitle className="mb-2">{storyPage.body[0].title}</Card.Subtitle>
                  <Card.Text>
                    {storyPage.body[0].text}
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
            <a className="social-card-link m-2 text-center" href="https://www.twitter.com/pinavomaps/">
              <Card className="social-card">
                <Card.Body>
                  <Card.Title><FontAwesomeIcon icon={faTwitter} size="2x" color="#3771c8" aria-label="Go to Twitter"/></Card.Title>
                  <Card.Subtitle className="mb-2">{storyPage.body[1].title}</Card.Subtitle>
                  <Card.Text>
                    {storyPage.body[1].text}
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
            <a className="social-card-link m-2 text-center" href="https://www.linkedin.com/company/pinavo">
              <Card className="social-card">
                <Card.Body>
                  <Card.Title><FontAwesomeIcon icon={faLinkedin} size="2x" color="#3771c8" aria-label="Go to LinkedIn"/></Card.Title>
                  <Card.Subtitle className="mb-2">{storyPage.body[2].title}</Card.Subtitle>
                  <Card.Text>
                    {storyPage.body[2].text}
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Row>
          <Row className="social-cards pb-4 justify-content-center">
            <a className="social-card-link m-2 text-center" href="https://medium.com/pinavo">
              <Card className="social-card">
                <Card.Body>
                  <Card.Title><FontAwesomeIcon icon={faMedium} size="2x" color="#3771c8" aria-label="Go to Medium"/></Card.Title>
                  <Card.Subtitle className="mb-2">{storyPage.body[3].title}</Card.Subtitle>
                  <Card.Text>
                    {storyPage.body[3].text}
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
            <a className="social-card-link m-2 text-center" href="https://discord.gg/FhsTrhm">
              <Card className="social-card">
                <Card.Body>
                  <Card.Title><FontAwesomeIcon icon={faDiscord} size="2x" color="#3771c8" aria-label="Go to Discord"/></Card.Title>
                  <Card.Subtitle className="mb-2">{storyPage.body[4].title}</Card.Subtitle>
                  <Card.Text>
                    {storyPage.body[4].text}
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
            <a className="social-card-link m-2 text-center" href="https://connect.garmin.com/modern/group/3355182">
              <Card className="social-card">
                <Card.Body>
                  <Card.Title><FontAwesomeIcon icon={faHiking} size="2x" color="#3771c8" aria-label="Go to Garmin Connect"/></Card.Title>
                  <Card.Subtitle className="mb-2">{storyPage.body[5].title}</Card.Subtitle>
                  <Card.Text>
                    {storyPage.body[5].text}
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
            <a className="social-card-link m-2 text-center" href="https://www.facebook.com/pinavo.maps/">
              <Card className="social-card">
                <Card.Body>
                  <Card.Title><FontAwesomeIcon icon={faFacebook} size="2x" color="#3771c8" aria-label="Go to Facebook"/></Card.Title>
                  <Card.Subtitle className="mb-2">{storyPage.body[6].title}</Card.Subtitle>
                  <Card.Text>
                    {storyPage.body[6].text}
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Row>
        </Container>
        <Footer />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query FollowUsQuery {
    page: allStoryblokEntry(filter: {slug: {eq: "follow-us"}})  {
      edges {
        node {
          content
        }
      }
    }
  }
`
